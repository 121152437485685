import React, { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { LuSendHorizontal } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    ADMIN,
    DASHBOARD,
    GET_PLANT_BY_ID_ENDPOINT,
} from "../../constants/routes";
import request from "../../services/request";
import Counter from "../../shared/animation/typography/Counter";
import Container from "../../shared/Container";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import Tag from "../../shared/ui/tag/Tag";
const HeroDetails = () => {
    const { propertyId } = useParams();
    const [propertyName, setPropertyName] = useState("");
    const [formData, setFormData] = useState({});
    const [dis, setDis] = useState(true);
    const [nameError, setNameError] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${GET_PLANT_BY_ID_ENDPOINT}/${propertyId}`;
            const data = await request.authGet({ endpoint });
            setFormData(data?.data?.plant);
            setPropertyName(data?.data?.plant?.propertyName);
        };
        fetchData().catch((err) => {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        });
    }, []);
    const updatePropertyNameHandler = async () => {
        if (propertyName === formData["propertyName"]) {
            setNameError("Property name can't be same");
        } else {
            try {
                let res = await request.authPost({
                    endpoint: `${GET_PLANT_BY_ID_ENDPOINT}/${propertyId}?plantName=${propertyName}`,
                });
                if (res?.data?.Plant?.propertyName) {
                    setFormData((prev) => ({
                        ...prev,
                        propertyName: res?.data?.Plant?.propertyName,
                    }));
                }

                setDis(false);
                setNameError("");
                toast.success(
                    typeof res?.message === "string"
                        ? res?.message
                        : "Plant name has been updated"
                );
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);

                setDis(false);
                setNameError("");
            }
        }
    };
    const history = useHistory();
    const handlePropertyName = (e) => {
        setPropertyName(e.target.value);
    };
    return (
        <Container className="relative bottom-0 top-20 sm:top-10 py-1 lg:py-0  px-2 bg-white rounded-lg border border-blue-300 min-h-56">
            <div
                className="flex items-center gap-1 mb-2 md:mb-0"
                role="button"
                onClick={() => history.push(`${ADMIN}${DASHBOARD}`)}
            >
                <P>
                    <GoArrowLeft />
                </P>
                <P>Go Back</P>
            </div>
            <div className="flex flex-col items-stretch sm:flex-row justify-between sm:items-center gap-3.6">
                <div className="lg:mt-2">
                    <H4 className="text-nowrap mb-1">Proposals details</H4>
                    <Tag
                        text={`Plant #${propertyId}`}
                        className={
                            "text-secondary w-full bg-white border border-blue-400 py-1"
                        }
                    />
                </div>
                <div className="lg:mt-2">
                    <div>
                        <H4 className="text-nowrap">Property Name</H4>
                        <TextField
                            label={""}
                            value={propertyName}
                            onChange={handlePropertyName}
                            size={"md:min-w-[24rem]"}
                            name="name"
                            placeholder=""
                            disabled={dis}
                            errorMsg={nameError}
                            wrapper={"md:h-[5rem]"}
                            inputWrapper="rounded-l-md text-clip xl:py-1 "
                            type="text"
                            icon={dis ? <SlPencil /> : <LuSendHorizontal />}
                            iconWrapperClass={`py-[1.4rem] px-[1.5rem]  flex items-center rounded-r-md bg-gray-100  `}
                            iconChange={() => {
                                setNameError("");
                                setDis((prev) => !prev);
                                if (!dis) {
                                    updatePropertyNameHandler();
                                }
                            }}
                        />
                    </div>
                    {!dis && (
                        <div
                            className="my-1 !border-none text-red cursor-pointer"
                            onClick={() => {
                                setDis((prev) => !prev);
                                setPropertyName(formData?.propertyName);
                            }}
                        >
                            Cancel
                        </div>
                    )}
                </div>
                <div className="hidden xl:flex">
                    <div className=" flex flex-row justify-between items-start gap-1 sm:gap-4 lg:gap-3 xl:gap-3   my-3.2">
                        <div>
                            <H4 className=" text-nowrap text-secondary">
                                Monthly Electricity Bill
                            </H4>
                            <P>
                                <Counter
                                    start={
                                        Number(formData.monthlyElectricBill) -
                                        200
                                    }
                                    value={formData.monthlyElectricBill}
                                />
                            </P>
                        </div>
                        <div class=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                        <div>
                            <H4 className=" text-nowrap text-secondary">
                                Site Address
                            </H4>
                            <P className="sm:text-5xl xl:text-base text-justify w-[60%]  line-clamp-2 hover:text-clip">
                                {formData.siteAddress}
                            </P>
                        </div>
                        <div class="min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                        <div>
                            <H4 className="  text-nowrap text-secondary">
                                Project Status
                            </H4>
                            <P className=" xl:text-base">
                                {formData.surveyStatus}
                            </P>
                        </div>
                    </div>
                </div>
                <div>
                    <Button className="lg:mt-2 !rounded-[3rem]">Survey</Button>
                </div>
            </div>

            <div className=" xl:hidden flex justify-center">
                <div className=" flex flex-wrap sm:flex-nowrap justify-between sm:justify-normal  gap-1 sm:gap-4 lg:gap-4 xl:gap-5   my-3.2">
                    <div>
                        <P className=" text-nowrap text-secondary">
                            Monthly Electricity Bill
                        </P>
                        <H4>
                            <Counter
                                start={
                                    Number(formData?.monthlyElectricBill) - 200
                                }
                                value={formData?.monthlyElectricBill}
                            />
                        </H4>
                    </div>
                    <div class=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                    <div className="md:text-left ">
                        <P className=" text-nowrap text-secondary">
                            Site Address
                        </P>
                        <H4 className="line-clamp-2 text-sm">
                            {formData?.siteAddress}
                        </H4>
                    </div>
                    <div class="  min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                    <div>
                        <P className="  text-nowrap text-secondary">
                            Project Status
                        </P>
                        <H4>{formData?.surveyStatus} </H4>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
