import React, { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { LuSendHorizontal } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { ERROR_MSG } from "../../constants/common";
import {
    ADMIN,
    DASHBOARD,
    GET_PLANT_BY_ID_ENDPOINT,
} from "../../constants/routes";
import cn from "../../lib/cn";
import request from "../../services/request";
import Container from "../../shared/Container";
import Counter from "../../shared/animation/typography/Counter";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import TextField from "../../shared/ui/Form/TextField";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import Tag from "../../shared/ui/tag/Tag";

const HeroDetails = ({
    currentTab,
    handleTabClick,
    selected,
    upfront,
    subsidyAmount,
    billWithSolar,
    solarPlantSize,
    options,
    isAdmin,
    billWithoutGST,
    connectionType,
}) => {
    const [dis, setDis] = useState(true);
    const [nameError, setNameError] = useState("");
    const { id } = useParams();
    const [propertyName, setPropertyName] = useState("");
    const history = useHistory();
    const [formData, setFormData] = useState({});
    const onTabClick = (index) => {
        handleTabClick(index);
    };
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${GET_PLANT_BY_ID_ENDPOINT}/${id}`;
            const data = await request.authGet({ endpoint });
            setFormData(data?.data?.plant);
            setPropertyName(data?.data?.plant?.propertyName);
        };
        fetchData().catch((err) => {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        });
    }, []);

    useEffect(() => {}, [billWithoutGST, subsidyAmount]);
    const updatePropertyNameHandler = async () => {
        if (propertyName === formData.propertyName) {
            setNameError("Property name can't be same");
        } else {
            try {
                let res = await request.authPost({
                    endpoint: `${GET_PLANT_BY_ID_ENDPOINT}/${id}?plantName=${propertyName}`,
                });
                if (res?.data?.Plant?.propertyName) {
                    setFormData((prev) => ({
                        ...prev,
                        propertyName: res?.data?.Plant?.propertyName,
                    }));
                }

                setDis(false);
                setNameError("");
                toast.success(
                    typeof res?.message === "string"
                        ? res?.message
                        : "Plant name has been updated"
                );
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);

                setDis(false);
                setNameError("");
            }
        }
    };
    const handlePropertyName = (e) => {
        setPropertyName(e.target.value);
    };
    return (
        <Container className="relative bottom-0 top-10   w-full py-1 px-1.5 mt-5 bg-white rounded-lg border border-blue-300 min-h-66">
            <div
                className="flex items-center gap-1 mb-2 md:mb-0"
                role="button"
                onClick={() => {
                    isAdmin
                        ? history.push(`${ADMIN}${DASHBOARD}`)
                        : history.goBack();
                }}
            >
                <P>
                    <GoArrowLeft />
                </P>
                <P>Go Back</P>
            </div>
            <div
                className={cn(
                    "flex  justify-between md:flex-nowrap items-start md:items-center gap-1 xl:gap-4.6",
                    {
                        "flex-wrap": options.length === 3,
                    }
                )}
            >
                <div className="flex flex-col gap-1 md:flex-row  md:gap-3.2 items-center">
                    <div>
                        <H4 className="text-nowrap">Proposals details</H4>
                        <Tag
                            text={`Plant #${id}`}
                            className={"text-secondary"}
                        />
                    </div>
                </div>
                <div className="w-[20%]">
                    <div className=" hidden lg:block text-pretty mt-1 lg:mt-2">
                        <div>
                            <TextField
                                label={""}
                                value={propertyName}
                                onChange={handlePropertyName}
                                size={"w-full md:min-w-[20rem] max-w-[40rem]"}
                                name="name"
                                placeholder=""
                                disabled={dis}
                                errorMsg={nameError}
                                wrapper={"md:h-[5rem]"}
                                inputWrapper="rounded-l-md text-clip "
                                type="text"
                                icon={dis ? <SlPencil /> : <LuSendHorizontal />}
                                iconWrapperClass={`py-[1.4rem] px-[1.5rem]  flex items-center rounded-r-md bg-gray-100  `}
                                iconChange={() => {
                                    setNameError("");
                                    setDis((prev) => !prev);
                                    if (!dis) {
                                        updatePropertyNameHandler();
                                    }
                                }}
                            />
                        </div>
                        {!dis && (
                            <div
                                className="my-1 !border-none text-red cursor-pointer"
                                onClick={() => {
                                    setDis((prev) => !prev);
                                    setPropertyName(formData?.propertyName);
                                }}
                            >
                                Cancel
                            </div>
                        )}
                    </div>
                    <div className="hidden xl:flex text-pretty text-ellipsis w-full  mt-1">
                        <P className="line-clamp-2"> {formData.siteAddress}</P>
                    </div>
                </div>
                <div className="hidden xl:flex flex-1 justify-center ">
                    <div className=" flex flex-row justify-between flex-wrap  gap-1 sm:gap-4 lg:gap-1.8 xl:gap-1   my-3.2">
                        <div className="md:text-center ">
                            <H4 className="sm:text-5xl xl:text-4xl">
                                {solarPlantSize} kWp
                            </H4>
                            <P className="  text-nowrap text-secondary">
                                Solar Plant Size
                            </P>
                        </div>
                        <div className="  min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>

                        <div className="md:text-center ">
                            <H4 className="sm:text-5xl xl:text-4xl">
                                ₹ {billWithoutGST.toLocaleString()}
                            </H4>
                            <P className="  text-nowrap text-secondary">
                                Monthly Bill Without Solar
                            </P>
                        </div>

                        {options[currentTab] === "Loan" && (
                            <>
                                <div className=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                                <div className="md:text-center">
                                    <H4 className="sm:text-5xl xl:text-4xl">
                                        ₹ {upfront}
                                    </H4>
                                    <P className=" text-nowrap text-secondary">
                                        Upfront Payment
                                    </P>
                                </div>
                            </>
                        )}
                        {(connectionType ===
                            connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
                            connectionType ===
                                connectionTypeToCategory.INDIVIDUAL_HOMES) && (
                            <>
                                <div className=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                                <div className="md:text-center">
                                    <H4 className="sm:text-5xl xl:text-4xl">
                                        ₹ {subsidyAmount}
                                    </H4>
                                    <P className=" text-nowrap text-secondary">
                                        Govt Subsidy
                                    </P>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <SwitchingTabs
                        options={options}
                        currentTab={currentTab}
                        onTabClick={onTabClick}
                        border={"!rounded-[3rem] lg:rounded-[3rem]"}
                        bestlabel={false}
                    />
                </div>
            </div>
            <div className="xl:hidden flex flex-col mt-2 line-clamp-2 items-center gap-3 text-ellipsis">
                <div className="w-full">
                    <div className=" w-full mt-1">
                        <TextField
                            label={""}
                            value={propertyName}
                            onChange={handlePropertyName}
                            size={"w-full md:min-w-[24rem]"}
                            name="name"
                            placeholder=""
                            disabled={dis}
                            errorMsg={nameError}
                            wrapper={"md:h-[5rem]"}
                            inputWrapper="rounded-l-md text-clip "
                            type="text"
                            icon={dis ? <SlPencil /> : <LuSendHorizontal />}
                            iconWrapperClass={`py-[1.4rem] px-[1.5rem]  flex items-center rounded-r-md bg-gray-100  `}
                            iconChange={() => {
                                setNameError("");
                                setDis((prev) => !prev);
                                if (!dis) {
                                    updatePropertyNameHandler();
                                }
                            }}
                        />
                    </div>
                    {!dis && (
                        <div
                            className="my-1 !border-none text-red cursor-pointer"
                            onClick={() => {
                                setDis((prev) => !prev);
                                setPropertyName(formData?.propertyName);
                            }}
                        >
                            Cancel
                        </div>
                    )}
                </div>
                <P>{formData.siteAddress}</P>
            </div>
            <div className=" xl:hidden flex justify-center">
                <div className=" grid grid-cols-2 sm:flex sm:flex-wrap  sm:justify-normal  gap-1 sm:gap-4 lg:gap-4 xl:gap-5   my-3.2">
                    <div className="flex ">
                        <div className="md:text-center">
                            <H4>₹ {subsidyAmount}</H4>
                            <P className=" text-nowrap text-secondary">
                                Govt Subsidy
                            </P>
                        </div>
                        <div class=" min-h-[1em] w-0.2 ml-3 sm:ml-0 self-stretch bg-gray-100"></div>
                    </div>
                    <div className="flex ">
                        <div className="md:text-left  ">
                            <H4>₹ {billWithoutGST.toLocaleString()}</H4>
                            <P className="  text-nowrap text-secondary">
                                Monthly Bill Without Solar
                            </P>
                        </div>
                        <div class="  min-h-[1em] hidden sm:block  w-0.2 self-stretch bg-gray-100"></div>
                    </div>
                    <div className="md:text-center ">
                        <H4>
                            <Counter value={solarPlantSize} /> kWp
                        </H4>
                        <P className="  text-nowrap text-secondary">
                            Solar Plant Size
                        </P>
                    </div>
                    {options[currentTab] === "Loan" && (
                        <div className="flex ">
                            <div className=" min-h-[1em] ml-3 sm:ml-0 w-0.2 self-stretch bg-gray-100"></div>
                            <div className="md:text-center">
                                <H4>₹ {upfront}</H4>
                                <P className=" text-nowrap text-secondary">
                                    Upfront Payment
                                </P>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
