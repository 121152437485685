import React from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import Button from "../../shared/ui/Button";
import Dropdown from "../../shared/ui/Dropdown/Dropdown";
import Checkbox from "../../shared/ui/Form/Checkbox";
import TextField from "../../shared/ui/Form/TextField";

const SideBar = ({
    selectedConnectionType,
    onConnectionTypeChange,
    consumersBill,
    handleConsumerBill,
    generateProposal,
    totalUnits,
    onTotalUnitsChange,
    propertyList,
    checkedState,
    handleCheckboxChange,
}) => {
    return (
        <div className="bg-white flex border-2 border-gray-220 border-dashed md:w-[35%] lg:w-[25%] xl:w-[30%] rounded-lg px-2 py-2 flex-col gap-3.2">
            <div className="flex flex-col gap-1">
                <label
                    className="text-secondary font-normal text-sm sm:text-base xl:text-lsm"
                    htmlFor={"enquiry"}
                >
                    Connection Type
                </label>
                <Dropdown
                    selected={selectedConnectionType}
                    onSelect={onConnectionTypeChange}
                    data={propertyList}
                    icon={<IoChevronDownOutline />}
                />
            </div>
            <TextField
                label={"Consumer’s Bill"}
                value={consumersBill}
                onChange={handleConsumerBill}
                disabled={
                    selectedConnectionType ===
                        connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
                    selectedConnectionType ===
                        connectionTypeToCategory.INDIVIDUAL_HOMES
                }
                name="consumersBill"
                placeholder="Consumer’s Bill"
                type="text"
            />
            <TextField
                label={"Consumer’s Total Unit"}
                value={totalUnits}
                onChange={onTotalUnitsChange}
                name="totalUnits"
                placeholder="Consumer’s Total Unit"
                type="text"
            />
            <div className="flex flex-col gap-1">
                <label
                    className="text-secondary font-normal text-sm sm:text-base xl:text-lsm"
                    htmlFor={"check"}
                >
                    Proposal Type
                </label>
                <div className=" flex items-center gap-3.2 ">
                    {Object.keys(checkedState).map((key) => (
                        <Checkbox
                            key={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            checked={checkedState[key]}
                            onChange={() => handleCheckboxChange(key)}
                        />
                    ))}
                </div>
            </div>
            <Button
                className="!rounded-[3rem]"
                onClick={generateProposal}
                disabled={consumersBill < 100}
            >
                Generate Proposal
            </Button>
        </div>
    );
};

export default SideBar;
