import React, { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { IoChevronDownOutline } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ModalAlt from "../../../components/modal/ModalAlt";
import Textarea from "../../../components/text-area/Textarea";
import { ERROR_MSG, ROLE } from "../../../constants/common";
import {
    categoryToConnectionType,
    connectionTypeToCategory,
    propertyList,
} from "../../../constants/InvestmentConstant";
import {
    PROPOSAL_UPDATE,
    SALES_PROPOSAL_BASE_ENDPOINT,
    SALES_PROPOSAL_UPDATE,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";

import { RESET_LOADER, SET_LOADER } from "../../../constants/contexConstant";
import WrapperMaster from "../../../pages/portal/proposal/WrapperMaster";
import { background } from "../../../resources/js/images";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Dropdown from "../../../shared/ui/Dropdown/Dropdown";
import Checkbox from "../../../shared/ui/Form/Checkbox";
import RadioInput from "../../../shared/ui/Form/RadioInput";
import TextField from "../../../shared/ui/Form/TextField";
import SwitchingTabs from "../../../shared/ui/tab/SwitchingTabs";
import Hero from "../../contactUs/Hero";

const FloatingButton = styled(Button)`
    position: fixed;
    bottom: 1rem;

    z-index: 1000;

    color: white;

    border-radius: 3rem;
    height: 5rem;
    disabled: ${(props) => props.disabled};
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeroDetails = () => {
    return (
        <div>
            <Container>
                <div className="flex justify-center items-end min-h-[140px] lg:min-h-[180px] xl:min-h-[250px]">
                    <H4 className="text-white text-lg sm:text-xl xl:text-2xl 2xl:text-5xl mb-4 xl:mb-7">
                        Edit Proposal Data
                    </H4>
                </div>
            </Container>
        </div>
    );
};
const plantDuration = {
    5: "5 Years",
    6: "6 Years",
    7: "7 Years",
    8: "8 Years",
    9: "9 Years",
    10: "10 Years",
};
const connectionType = {
    RESIDENTIAL_SOCIETY: "RESIDENTIAL SOCIETY",
    COMMERCIAL: "COMMERCIAL",
    INDUSTRIAL: "INDUSTRIAL",
    INDIVIDUAL_HOMES: "INDIVIDUAL HOMES",
};

const RevisionProposal = () => {
    const { state, dispatch } = useContextState();
    const [submitData, setSubmitData] = useState({});
    const { plantId, proposalId } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const fetchData = `${SALES_PROPOSAL_BASE_ENDPOINT}/${plantId}/${
        proposalId ? proposalId : "current"
    }`;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [dataServ, setDataServ] = useState({});
    const onClose = () => {
        setIsOpen(!isOpen);
    };
    const handleTabClick = (index) => {
        setCurrentSlide(index);
    };
    const handleData = (submitData) => {
        setSubmitData(submitData);
        setDataServ(submitData);
    };
    const handleDataField = (field, data) => {
        setSubmitData((prevFormData) => ({
            ...prevFormData,
            [field]: data,
        }));
    };

    const [initialData, setInitialData] = useState(null);
    const [errors, setErrors] = useState({
        consumerTotalUnits: "",
        consumerBill: "",
    });
    const [formData, setFormData] = useState({
        category: "",
        consumerBill: "",
        consumerTotalUnits: "",
        plantDuration: "",
        subsidy: false,
        tenant: false,
        comment: "",
    });
    const isServerCalculation =
        formData.category === connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
        formData.category === connectionTypeToCategory.INDIVIDUAL_HOMES;

    const fetchBillAmount = async (units) => {
        try {
            if (isServerCalculation && !isNaN(units)) {
                let res = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/getBill/residential/${Number(
                        units
                    )}`,
                });
                let bill = Number(res?.data?.billAmount).toFixed(2);
                if (bill) {
                    handleChange("consumerBill", Number(bill));
                } else {
                    toast.error(
                        "Can't fetch bill amount. Please try again later"
                    );
                }
            }
        } catch (error) {
            // toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };

    useEffect(() => {
        setCheckedState(
            formData.category === connectionType.RESIDENTIAL_SOCIETY
                ? { purchase: true, lease: true }
                : formData.category === connectionType.INDIVIDUAL_HOMES ||
                  formData.category === connectionType.INDUSTRIAL
                ? { purchase: true, loan: true }
                : { purchase: true, lease: true, loan: true }
        );
    }, [formData.category]);
    const initialCheckedState =
        formData.category === connectionType.RESIDENTIAL_SOCIETY
            ? { purchase: true, lease: true }
            : formData.category === connectionType.INDIVIDUAL_HOMES ||
              formData.category === connectionType.INDUSTRIAL
            ? { purchase: true, loan: true }
            : { purchase: true, lease: true, loan: true };

    const [checkedState, setCheckedState] = useState(initialCheckedState);
    const selectedOptions = Object.keys(checkedState)
        .filter((key) => checkedState[key])
        .map((option) => option.charAt(0).toUpperCase() + option.slice(1));

    const handleCheckboxChange = (key) => {
        const checkedCount = Object.values(checkedState).filter(Boolean).length;

        if (checkedCount === 1 && checkedState[key]) {
            toast.warn("At least one option must remain selected.");
            return;
        }
        setCheckedState((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    useEffect(() => {
        const handleCurrentProposal = async () => {
            try {
                dispatch({ type: SET_LOADER });
                const response = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/${plantId}/${
                        proposalId ? proposalId : "current"
                    }`,
                });
                dispatch({ type: RESET_LOADER });
                const proposal = response.data.proposal;

                setFormData({
                    category: (
                        connectionTypeToCategory[proposal.category] || ""
                    ).replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",
                    plantDuration: `${proposal.plantDuration / 12} Years` || "",
                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: "",
                });
                setInitialData({
                    category: (
                        connectionTypeToCategory[proposal.category] || ""
                    ).replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",

                    plantDuration: `${proposal.plantDuration / 12} Years` || "",

                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: "",
                    data: proposal?.data,
                });
            } catch (error) {
                dispatch({ type: RESET_LOADER });
                toast.error("Failed to fetch initial data");
            }
        };

        if (plantId) {
            handleCurrentProposal();
        }
    }, [plantId]);

    const handleChange = (field, value) => {
        let errorMessage = "";

        if (
            field === "consumerTotalUnits" &&
            value !== "" &&
            !/^\d+$/.test(value)
        ) {
            errorMessage = "Please enter a valid integer.";
        } else if (
            field === "consumerBill" &&
            value !== "" &&
            !/^\d*\.?\d+$/.test(value)
        ) {
            errorMessage = "Please enter a valid number.";
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleUpdateProposal = async (masterData) => {
        if (formData.comment.trim() === "") {
            toast.error("Admin comment is required");
            return;
        }
        if (!initialData) {
            toast.error("Failed to fetch initial data");
            return;
        }

        const updatedData = {};

        for (const key in formData) {
            if (formData[key] !== initialData[key] || key === "comment") {
                if (key === "category") {
                    updatedData[key] =
                        categoryToConnectionType[formData.category];
                } else if (key === "plantDuration") {
                    updatedData[key] = parseInt(formData[key]) * 12;
                } else {
                    updatedData[key] = formData[key];
                }
            }
        }

        if (
            submitData &&
            checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN])
        ) {
            const submitDataString = JSON.stringify(submitData);
            if (submitDataString !== initialData?.data)
                updatedData.data = submitDataString;
        }
        try {
            if (checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN])) {
                if (hasMoreThanOneDifference(updatedData, initialData)) {
                    dispatch({ type: SET_LOADER });
                    await request.authPut({
                        endpoint: `${PROPOSAL_UPDATE}/${plantId}`,
                        body: updatedData,
                    });
                    dispatch({ type: RESET_LOADER });
                }
            } else {
                dispatch({ type: SET_LOADER });
                await request.authPut({
                    endpoint: `${SALES_PROPOSAL_UPDATE}/${plantId}`,
                    body: updatedData,
                });
                dispatch({ type: RESET_LOADER });
            }

            toast.success("Proposal updated successfully");
            history.goBack();
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };

    const history = useHistory();
    const hasMoreThanOneDifference = (formData, initialData) => {
        let diffCount = 0;

        for (const key of Object.keys(formData)) {
            if (key === "comment") continue;

            if (formData[key] !== initialData[key]) {
                diffCount++;
                if (diffCount > 0) {
                    return true;
                }
            }
        }

        return false;
    };
    const editData = checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN])
        ? `${PROPOSAL_UPDATE}/${plantId}`
        : `${SALES_PROPOSAL_UPDATE}/${plantId}`;

    const handleFloatingButtonClick = () => {
        onClose();
    };

    return (
        <div className="bg-white h-full">
            <Hero
                content={<HeroDetails />}
                wrapperClass={"bg-blue-500"}
                url={background}
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
            <div className="-mt-2 lg:-mt-3 xl:-mt-5 bg-white p-0.5 lg:p-2 m-2 rounded-t-xl min-h-[600px] lg:h-full">
                <Section>
                    <Container className=" bg-white  md:min-h-[900px] ">
                        <div className="flex flex-wrap md:flex-nowrap  mb-2 justify-between">
                            <div
                                className="flex items-center gap-2 mb-1"
                                role="button"
                                onClick={() => history.goBack()}
                            >
                                <P>
                                    <GoArrowLeft />
                                </P>
                                <P>Go Back</P>
                            </div>
                            <div className="lg:hidden">
                                {checkIsAllowed(state?.user?.roles, [
                                    ROLE.SUPER_ADMIN,
                                ]) && (
                                    <SwitchingTabs
                                        options={[
                                            "Proposal Data",
                                            "Master data",
                                        ]}
                                        currentTab={currentSlide}
                                        onTabClick={handleTabClick}
                                        border={"rounded-full lg:rounded-5xl"}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="lg:hidden ">
                            {currentSlide === 0 ? (
                                <div className="bg-white  w-full p-1.6 xl:p-2.4 mt-2 rounded-sm border-2 border-gray-220 border-dashed ">
                                    <div className=" flex flex-col gap-2  w-full mb-2">
                                        <Dropdown
                                            selected={formData.category}
                                            onSelect={(value) =>
                                                handleChange("category", value)
                                            }
                                            label={"Connection Type"}
                                            className={"flex-1"}
                                            data={propertyList}
                                            icon={<IoChevronDownOutline />}
                                        />
                                        <TextField
                                            size={"flex-1"}
                                            label={"Consumer's Total Unit"}
                                            placeholder="Consumers Total Unit"
                                            value={formData.consumerTotalUnits}
                                            onChange={(e) => {
                                                handleChange(
                                                    "consumerTotalUnits",
                                                    e.target.value
                                                );
                                                {
                                                    isServerCalculation &&
                                                        fetchBillAmount(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                }
                                            }}
                                            errorMsg={errors.consumerTotalUnits}
                                            name="totalUnits"
                                            type="text"
                                        />
                                        <TextField
                                            size={"flex-1"}
                                            label={"Consumer's Bill"}
                                            placeholder="Consumers Bill"
                                            value={formData.consumerBill}
                                            onChange={(e) =>
                                                handleChange(
                                                    "consumerBill",
                                                    e.target.value
                                                )
                                            }
                                            disabled={isServerCalculation}
                                            errorMsg={errors.consumerBill}
                                            name="consumerBill"
                                            type="text"
                                        />
                                        <div className="flex flex-col gap-0.5">
                                            <label className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-1 ">
                                                Proposal Type
                                            </label>
                                            <div className=" flex items-center gap-3.2 ">
                                                {Object.keys(checkedState).map(
                                                    (key) => (
                                                        <Checkbox
                                                            key={key}
                                                            label={
                                                                key
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                key.slice(1)
                                                            }
                                                            checked={
                                                                checkedState[
                                                                    key
                                                                ]
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    key
                                                                )
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <Dropdown
                                            selected={formData.plantDuration}
                                            onSelect={(value) =>
                                                handleChange(
                                                    "plantDuration",
                                                    value
                                                )
                                            }
                                            label={"Plan Duration"}
                                            className={"flex-1"}
                                            data={Object.values(plantDuration)}
                                            icon={<IoChevronDownOutline />}
                                        />
                                        {formData.category ===
                                            connectionType.COMMERCIAL && (
                                            <div>
                                                <P>Tenant</P>

                                                <div className="flex items-center gap-2">
                                                    <RadioInput
                                                        id="tenantYes"
                                                        label="Yes"
                                                        value="Yes"
                                                        name="tenant"
                                                        checked={
                                                            formData.tenant ===
                                                            true
                                                        }
                                                        onChange={() =>
                                                            handleChange(
                                                                "tenant",
                                                                true
                                                            )
                                                        }
                                                    />

                                                    <RadioInput
                                                        id="tenantNo"
                                                        label="No"
                                                        value="No"
                                                        name="tenant"
                                                        checked={
                                                            formData.tenant ===
                                                            false
                                                        }
                                                        onChange={() =>
                                                            handleChange(
                                                                "tenant",
                                                                false
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {formData.category ===
                                            connectionType.INDUSTRIAL && (
                                            <div>
                                                <P>
                                                    Government Subsidy / Tenant
                                                </P>
                                                <br />
                                                <div className="flex items-center gap-2">
                                                    <RadioInput
                                                        id="governmentSubsidNo"
                                                        label="No"
                                                        value="No"
                                                        name="governmentSubsidy"
                                                        checked={true}
                                                        onChange={() =>
                                                            handleChange(
                                                                "subsidy",
                                                                false
                                                            )
                                                        }
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {formData.category !==
                                            connectionType.COMMERCIAL &&
                                            formData.category !==
                                                connectionType.INDUSTRIAL && (
                                                <div>
                                                    <P>Government Subsidy</P>
                                                    <br />
                                                    <div className="flex items-center gap-2">
                                                        <RadioInput
                                                            id="governmentSubsidYes"
                                                            label="Yes"
                                                            value="Yes"
                                                            name="governmentSubsidy"
                                                            checked={
                                                                formData.subsidy
                                                            }
                                                            onChange={() =>
                                                                handleChange(
                                                                    "subsidy",
                                                                    true
                                                                )
                                                            }
                                                            color="primary"
                                                        />

                                                        <RadioInput
                                                            id="governmentSubsidNo"
                                                            label="No"
                                                            value="No"
                                                            name="governmentSubsidy"
                                                            checked={
                                                                !formData.subsidy
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                handleChange(
                                                                    "subsidy",
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        {/* <Button
                                        className="flex-1 !rounded-[3rem]"
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                        Update
                                    </Button> */}
                                    </div>
                                </div>
                            ) : (
                                checkIsAllowed(state?.user?.roles, [
                                    ROLE.SUPER_ADMIN,
                                ]) && (
                                    <div className="mt-2">
                                        <WrapperMaster
                                            sectionClass={
                                                "w-full md:mb-0 lg:mb-0 xl:mb-0"
                                            }
                                            containerClass={"px-0 mx-0"}
                                            fetchData={fetchData}
                                            editData={editData}
                                            label={false}
                                            showButton={false}
                                            handleData={handleData}
                                            handleDataField={handleDataField}
                                            onSubmit={handleUpdateProposal}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                        <div className=" hidden lg:flex flex-wrap  rounded-sm border-2 border-gray-200 p-1.6 xl:p-2.4 md:flex-nowrap gap-1 items-stretch py-1.5">
                            <div className="flex-1 ">
                                <div className="  w-full ">
                                    <div className="bg-white  w-full p-1.6 xl:p-2.4 rounded-sm border-2 border-gray-220 border-dashed ">
                                        <div className=" flex flex-col gap-2  w-full mb-2">
                                            <Dropdown
                                                selected={formData.category}
                                                onSelect={(value) =>
                                                    handleChange(
                                                        "category",
                                                        value
                                                    )
                                                }
                                                label={"Connection Type"}
                                                className={"flex-1"}
                                                data={propertyList}
                                                icon={<IoChevronDownOutline />}
                                            />
                                            <TextField
                                                size={"flex-1"}
                                                label={"Consumer's Total Unit"}
                                                placeholder="Consumers Total Unit"
                                                value={
                                                    formData.consumerTotalUnits
                                                }
                                                onChange={(e) => {
                                                    handleChange(
                                                        "consumerTotalUnits",
                                                        e.target.value
                                                    );
                                                    {
                                                        isServerCalculation &&
                                                            fetchBillAmount(
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                    }
                                                }}
                                                errorMsg={
                                                    errors.consumerTotalUnits
                                                }
                                                name="totalUnits"
                                                type="text"
                                            />
                                            <TextField
                                                size={"flex-1"}
                                                label={"Consumer's Bill"}
                                                placeholder="Consumers Bill"
                                                value={formData.consumerBill}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "consumerBill",
                                                        e.target.value
                                                    )
                                                }
                                                disabled={isServerCalculation}
                                                errorMsg={errors.consumerBill}
                                                name="consumerBill"
                                                type="text"
                                            />
                                            <div className="flex flex-col gap-0.5">
                                                <label className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-1 ">
                                                    Proposal Type
                                                </label>
                                                <div className=" flex items-center gap-3.2 ">
                                                    {Object.keys(
                                                        checkedState
                                                    ).map((key) => (
                                                        <Checkbox
                                                            key={key}
                                                            label={
                                                                key
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                key.slice(1)
                                                            }
                                                            checked={
                                                                checkedState[
                                                                    key
                                                                ]
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    key
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <Dropdown
                                                selected={
                                                    formData.plantDuration
                                                }
                                                onSelect={(value) =>
                                                    handleChange(
                                                        "plantDuration",
                                                        value
                                                    )
                                                }
                                                label={"Plan Duration"}
                                                className={"flex-1"}
                                                data={Object.values(
                                                    plantDuration
                                                )}
                                                icon={<IoChevronDownOutline />}
                                            />
                                            {formData.category ===
                                                connectionType.COMMERCIAL && (
                                                <div>
                                                    <P>Tenant</P>

                                                    <div className="flex items-center gap-2">
                                                        <RadioInput
                                                            id="tenantYes"
                                                            label="Yes"
                                                            value="Yes"
                                                            name="tenant"
                                                            checked={
                                                                formData.tenant
                                                            }
                                                            onChange={() =>
                                                                handleChange(
                                                                    "tenant",
                                                                    true
                                                                )
                                                            }
                                                        />

                                                        <RadioInput
                                                            id="tenantNo"
                                                            label="No"
                                                            value="No"
                                                            name="tenant"
                                                            checked={
                                                                !formData.tenant
                                                            }
                                                            onChange={() =>
                                                                handleChange(
                                                                    "tenant",
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {formData.category ===
                                                connectionType.INDUSTRIAL && (
                                                <div>
                                                    <P>
                                                        Government Subsidy /
                                                        Tenant
                                                    </P>
                                                    <br />
                                                    <div className="flex items-center gap-2">
                                                        <RadioInput
                                                            id="governmentSubsidNo"
                                                            label="No"
                                                            value="No"
                                                            name="governmentSubsidy"
                                                            checked={true}
                                                            onChange={() =>
                                                                handleChange(
                                                                    "subsidy",
                                                                    false
                                                                )
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {formData.category !==
                                                connectionType.COMMERCIAL &&
                                                formData.category !==
                                                    connectionType.INDUSTRIAL && (
                                                    <div>
                                                        <P>
                                                            Government Subsidy
                                                        </P>
                                                        <br />
                                                        <div className="flex items-center gap-2">
                                                            <RadioInput
                                                                id="governmentSubsidYes"
                                                                label="Yes"
                                                                value="Yes"
                                                                name="governmentSubsidy"
                                                                checked={
                                                                    formData.subsidy ===
                                                                    true
                                                                }
                                                                onChange={() =>
                                                                    handleChange(
                                                                        "subsidy",
                                                                        true
                                                                    )
                                                                }
                                                                color="primary"
                                                            />

                                                            <RadioInput
                                                                id="governmentSubsidNo"
                                                                label="No"
                                                                value="No"
                                                                name="governmentSubsidy"
                                                                checked={
                                                                    !formData.subsidy
                                                                }
                                                                color="primary"
                                                                onChange={() =>
                                                                    handleChange(
                                                                        "subsidy",
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                            <Button
                                                className="flex-1 !rounded-[3rem]"
                                                onClick={() => {
                                                    onClose();
                                                }}
                                                disabled={
                                                    formData?.consumerBill <
                                                        100 ||
                                                    (dataServ === submitData &&
                                                        !hasMoreThanOneDifference(
                                                            formData,
                                                            initialData
                                                        ))
                                                }
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[99%] md:w-[60%]">
                                {checkIsAllowed(state?.user?.roles, [
                                    ROLE.SUPER_ADMIN,
                                ]) && (
                                    <WrapperMaster
                                        sectionClass={
                                            "w-full md:mb-0 lg:mb-0 xl:mb-0"
                                        }
                                        showButton={false}
                                        handleData={handleData}
                                        handleDataField={handleDataField}
                                        containerClass={"px-0 mx-0"}
                                        fetchData={fetchData}
                                        editData={editData}
                                        label={false}
                                        onSubmit={handleUpdateProposal}
                                    />
                                )}
                            </div>
                        </div>
                        <ModalAlt
                            isOpen={isOpen}
                            backgroundColor="white"
                            onClose={onClose}
                            innerTimes={false}
                            topPadding={"5px"}
                        >
                            <div className="flex flex-col gap-1">
                                <Textarea
                                    value={formData.comment}
                                    onChange={(value) => {
                                        handleChange("comment", value);
                                    }}
                                    placeholder={"Admin's Comment"}
                                    type="text"
                                    name="comment"
                                    style={{
                                        border: "0.1px solid #7398E6",
                                        paddingBottom: "7px",
                                        paddingTop: "7px",
                                        backgroundColor: "white",
                                    }}
                                    rows="6"
                                />
                                <Button
                                    className="flex-1 !rounded-[3rem]"
                                    onClick={() => {
                                        handleUpdateProposal(null);
                                        onClose();
                                    }}
                                    disabled={
                                        formData?.consumerBill < 100 ||
                                        (dataServ === submitData &&
                                            !hasMoreThanOneDifference(
                                                formData,
                                                initialData
                                            )) ||
                                        formData.comment.trim() === ""
                                    }
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalAlt>
                        {formData.consumerBill > 100 && (
                            <div className="block lg:hidden">
                                <FloatingButton
                                    className="flex w-[95%] mr-1 left-1  md:left-2"
                                    onClick={() => {
                                        handleFloatingButtonClick();
                                    }}
                                    disabled={
                                        formData?.consumerBill < 100 ||
                                        (dataServ === submitData &&
                                            !hasMoreThanOneDifference(
                                                formData,
                                                initialData
                                            ))
                                    }
                                >
                                    Update
                                </FloatingButton>
                            </div>
                        )}
                    </Container>
                </Section>
            </div>
        </div>
    );
};

export default RevisionProposal;
